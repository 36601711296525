import React from 'react';
import PropTypes from 'prop-types';
import styles from './Preloader.module.sass'
import cs from 'classnames';

const Preloader = ({isActive}) => (
    <div className={cs(styles.preloaderWrap, {[styles.nonActive]: !isActive})}></div>
)

Preloader.propTypes = {
  isActive: PropTypes.bool
}

Preloader.defaultProps = {
  isActive: true
}

export default Preloader
