import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavBar from '../components/shared/NavBar/NavBar';
import GlobalStyles from './GlobalStyles';
import Footer from '../components/shared/Footer/Footer';
import Subscribe from '../components/Subscribe/Subscribe';
import Preloader from 'src/components/shared/Preloader/Preloader';

class Layout extends Component {
  state = {
    subscriptionIsActive: true,
    preloaderIsActive: true
  };

  componentDidMount() {
    const WOW = require('wow.js')
    new WOW().init();
    this.setState({preloaderIsActive: false})
  }

  toggleSubscription = () => {
    this.setState(prevState => ({
      subscriptionIsActive: !prevState.subscriptionIsActive,
    }));
  };

  render() {
    const { children, navWhite } = this.props;
    const { subscriptionIsActive, preloaderIsActive } = this.state;
    return (
      <GlobalStyles>
        {/*<Preloader isActive={preloaderIsActive}/>*/}
        <NavBar navWhite={navWhite}/>
        <main className="siteWrap">
          {children}
          <div>
            <Subscribe isActive={subscriptionIsActive}/>
            <Footer toggleSubscription={this.toggleSubscription}/>
          </div>
        </main>
      </GlobalStyles>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navWhite: PropTypes.bool,
};

Layout.defaultProps = {
  navWhite: false,
};

export default Layout;
