import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonSubmit.module.sass';

const ButtonSubmit = ({ children, type }) => (
  <button type={type} className={styles.button}>{children}</button>
);

ButtonSubmit.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired
};

export default ButtonSubmit;