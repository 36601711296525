import React from 'react';
import { Link } from 'gatsby';
import styles from './Footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = ({toggleSubscription}) => (
  <footer className={styles.footer}>
    <ul className={styles.socialMedia}>
      <li className={styles.socialItem}>
        <a className={styles.socialLink}  href="https://instagram.com/twojgrecki?igshid=NTc4MTIwNjQ2YQ==" target="_blank">
          <FontAwesomeIcon icon={faInstagram}/>
        </a>
      </li>
      <li className={styles.socialItem}>
        <a className={styles.socialLink}  href="https://www.facebook.com/profile.php?id=100066699900234" target="_blank">
          <FontAwesomeIcon icon={faFacebookF}/>
        </a>
      </li>
    </ul>
    <div className={styles.menu}>
      <ul className={styles.footerMenu}>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/o-mnie">o mnie</Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/czytam">Czytam</Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/slucham">Słucham</Link>
        </li>
      </ul>
    </div>
    <div className={styles.contact}>
      <p className={styles.title}>Kontakt</p>
      <a className={styles.contactLink} href="tel: 507822267">507 822 267</a>
      <a className={styles.contactLink} href="mail: nowogrecki@gmail.com">nowogrecki@gmail.com</a>
    </div>
  </footer>
);

export default Footer;
