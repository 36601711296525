import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './Subscribe.module.scss';
import ArticleTitle from '../shared/texts/ArticleTitle/ArticleTitle';
import Input from '../shared/Input/Input';
import ButtonSubmit from '../shared/buttons/ButtonSubmit/ButtonSubmit';
import { Formik } from 'formik';
import Recaptcha from 'react-google-invisible-recaptcha';
import axios from "axios";





class Subscribe extends Component {
  state = {
    // initialValues: {
    //     email: '',
    //     name: '',
    //     phone: '',
    //     message: ''
    // },
    afterMessage: false,
  };
  constructor(props) {
      super(props);
    this.recaptchaRef = React.createRef();
  }

  saveAddress = (values) => {
      const form_data = new FormData();
      for ( const key in values ) {
          form_data.append(key,  values[key]);
      }
      this.setState({
          afterMessage:  true,
      });
      axios({
          method: 'post',
          url: 'https://makadu.cfolks.pl/projects/mm/api/contact/',
          data: form_data,
      }).then((item) => {

      }).catch((error) => {
              console.error(error);
          });
   };

  render() {
    const { isActive } = this.props;
    const { afterMessage } = this.state;
    return (
      <section className={cs(styles.subscribeWrap, { [styles.isActive]: isActive })} id="kontakt">
        <ArticleTitle className={styles.title}>Umów się na lekcję</ArticleTitle>
        <p className={styles.subtitle}>Wypełnij formularz, a skontaktuję się z Tobą!</p>
        <Formik
          initialValues={
              {
                  email: '',
                  name: '',
                  phone: '',
                  message: ''
              }}
          onSubmit={(values,formikHelpers) => {
                this.saveAddress(values);
                formikHelpers.setFormikState({
                    values: {
                    email: '',
                    name: '',
                    phone: '',
                    message: ''}
                })
                formikHelpers.resetForm();
             }
          }
        >
          {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <Input
                name="email"
                className={styles.input}
                placeholder="E-mail"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />
                <Input
                name="name"
                className={styles.input}
                placeholder="Imię"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
              />
                <Input
                name="phone"
                className={styles.input}
                placeholder="Numer telefonu"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                required
              />
            <textarea
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Twoja wiadomość"}
                value={values.message}
                className={cs(styles.textarea, styles.input)}
                required name="message" id="message">
            </textarea>
                {/*<Recaptcha*/}
                {/*    sitekey={process.env.RECAPTCHA_KEY}*/}
                {/*    ref={this.recaptchaRef}*/}
                {/*    size="invisible"*/}
                {/*/>*/}
              <p className={cs(styles.afterSubscribe, { [styles.isActive]: afterMessage })}>Dziękuję za
                wiadomość!</p>
              <ButtonSubmit type="submit" disabled={isSubmitting}>Zapisz się</ButtonSubmit>
            </form>
          )}
        </Formik>
      </section>
    );
  }
}

Subscribe.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Subscribe;
