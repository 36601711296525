import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './Burger.module.scss';

const Burger = ({ active, onClick }) => (
  <button className={cs(styles.burger, { [styles.active]: active })} onClick={onClick}>
    <span className={styles.span}></span>
    <span className={styles.span}></span>
  </button>
);

Burger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default Burger;
