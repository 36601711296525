import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './Input.module.scss';

const Input = ({ className, placeholder, name, type, onChange, onBlur, value, required }) => (
  <input
    className={cs(className, styles.input)}
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    required={required}/>
);

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool
};

Input.defaultProps = {
  className: '',
  required: false
};

export default Input;
